import * as types from 'constants/action_types'
import { isEmpty } from 'util/arrays'
import { selectPossibleMailboxesForChange } from 'ducks/mailboxes/selectors/selectPossibleMailboxesForChange'

export const doOpenChangeInboxMenu = e => (dispatch, getState) => {
  const mailboxes = selectPossibleMailboxesForChange(getState())
  if (isEmpty(mailboxes)) return false
  // NOTE (jscheel): Sometimes this doesn't get called with an event object. Would
  // probably be good to track down why the keyboard shortcut isn't passing the event on.
  if (e && e.stopPropagation) {
    e.stopPropagation()
  }
  return dispatch({
    type: types.OPEN_CHANGE_INBOX_MENU,
  })
}

export function doCloseChangeInboxMenu() {
  return {
    type: types.CLOSE_CHANGE_INBOX_MENU,
  }
}

export function doOpenMoreTicketOptionsMenu() {
  return {
    type: types.OPEN_MORE_TICKET_OPTIONS_MENU,
  }
}

export function doCloseMoreTicketOptionsMenu() {
  return {
    type: types.CLOSE_MORE_TICKET_OPTIONS_MENU,
  }
}
