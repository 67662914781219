import { doTogglePrintMode } from 'actions/app'
import { handlePrintClick } from 'components/App/DesktopView/Layout/TicketInspector/Changesets/ExpandedMessage/util'
import { doFetchTicketEventsGroups } from './doFetchTicketEventsGroups'

export const doPrintWholeTicket = ticketId => async dispatch => {
  await dispatch(doFetchTicketEventsGroups(ticketId))
  dispatch(doTogglePrintMode(true))
  setTimeout(() => {
    handlePrintClick(null, document.getElementById('printableWholeTicket'))
    dispatch(doTogglePrintMode(false))
  }, 500)
}
