import grooveAPI from 'api/groove'
import {
  SEARCH_USER_REQUEST,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_COMPLETE,
  SEARCH_USER_FAIL,
} from 'constants/action_types'
import {
  oauthTokenSelector,
  selectAccountPrefersScopedContactsSearch,
} from 'selectors/app'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectMailboxesById } from 'ducks/mailboxes/selectors/selectMailboxesById'
import { selectKnownMailboxes } from 'ducks/mailboxes/selectors/selectKnownMailboxes'
import { selectDraftByTicketId } from 'ducks/drafts2/selectors'
import { selectPreviousUserSearchResults } from 'selectors/users'
import debug from 'util/debug'
import { batchActions } from 'util/redux'
import { emptyObj } from 'util/objects'
import { changeEntity, mergeEntityChanges } from 'ducks/entities/actionUtils'
import {
  LEGACY_AGENT_ROLE_ADMIN,
  LEGACY_AGENT_ROLE_AGENT,
  LEGACY_AGENT_ROLE_OWNER,
} from 'ducks/agents/constants'

export function doSearchUsers(term) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const ticketId = selectCurrentTicketId(state)
    const { isForwarding, mailboxId } =
      selectDraftByTicketId(state, ticketId, 'reply') || emptyObj

    dispatch({ type: SEARCH_USER_REQUEST })

    const success = users => ({
      type: SEARCH_USER_SUCCESS,
      data: { term, isForwarding, users },
      ...mergeEntityChanges(
        users.map(user => {
          if (!user.contact_id) return null
          return changeEntity('contacts', user.contact_id, {
            ...user,
            id: user.contact_id,
          })
        })
      ),
    })
    const complete = { type: SEARCH_USER_COMPLETE }

    const cacheKey = (isForwarding ? 'forward:' : '') + term
    const previousSearchResults = selectPreviousUserSearchResults(state)[
      cacheKey
    ]
    if (previousSearchResults) {
      dispatch(batchActions([success(previousSearchResults), complete]))
      return Promise.resolve({ contacts: previousSearchResults })
    }

    const exists = ['email']
    const defaultRoles = [
      LEGACY_AGENT_ROLE_ADMIN,
      LEGACY_AGENT_ROLE_OWNER,
      LEGACY_AGENT_ROLE_AGENT,
    ]
    const role = defaultRoles.concat(isForwarding ? 'collaborator' : 'enduser')

    const scopeByMailbox = selectAccountPrefersScopedContactsSearch(state)
    let mailboxIds = null
    if (scopeByMailbox) {
      const mailboxesById = selectMailboxesById(state)
      const draftMailbox = mailboxesById[mailboxId]
      if (draftMailbox) {
        mailboxIds = [draftMailbox.id]
      } else {
        const known = selectKnownMailboxes(state)
        if (known && known[0] && known[0].id) {
          mailboxIds = [known[0].id]
        }
      }
    }

    return grooveAPI
      .get(token, 'v1/contacts.json', {
        term,
        exists,
        role,
        mailbox_ids: mailboxIds,
      })
      .then(res => {
        dispatch(success(res.json.contacts))
        return res.json
      })
      .catch(err => {
        debug(err)
        dispatch({ type: SEARCH_USER_FAIL })
        return null
      })
      .then(contacts => {
        dispatch(complete)
        return contacts
      })
  }
}
