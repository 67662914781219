import {
  selectIsTicketSelectionMode,
  selectSelectedTicketIds,
} from 'selectors/ticket_list/base'
import { doBulkTrash } from 'ducks/tickets/actions/doBulkTrash'
import { doBulkDelete } from 'ducks/tickets/actions/doBulkDelete'
import { doBulkToggleTicketState } from 'ducks/tickets/actions/doBulkToggleTicketState'
import { doBulkStar } from 'ducks/tickets/actions/doBulkStar'
import { doBulkUnstar } from 'ducks/tickets/actions/doBulkUnstar'
import { doBulkUpdateState } from 'ducks/tickets/actions/doBulkUpdateState'
import { SPAM, UNREAD } from 'ducks/tickets/constants'
import { doBulkUpdateChannel } from 'ducks/tickets/actions/doBulkUpdateChannel'
import { getLength, all } from 'util/arrays'

import {
  selectAreAllSelectedStarred,
  selectCurrentOrSelectedTickets,
} from 'selectors/ticket_list'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { doBulkAssign } from 'ducks/tickets/actions/doBulkAssign'
import { selectCurrentConversationsById } from 'ducks/tickets/selectors'
import { isDeleted } from 'ducks/tickets/utils/state'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

function detectTicketNotDeleted(byId) {
  return id => {
    return byId[id] && !isDeleted(byId[id])
  }
}

export const isDeleteHard = (ticketIds, state) => {
  const byId = selectCurrentConversationsById(state)
  return !ticketIds.some(detectTicketNotDeleted(byId))
}

export function doBulkSpamSelectedTickets() {
  return doBulkUpdateStateSelectedTickets(SPAM)
}

export function doBulkUnreadSelectedTickets() {
  return doBulkUpdateStateSelectedTickets(UNREAD)
}

export function doBulkUpdateStateSelectedTickets(newState) {
  return (dispatch, getState) => {
    const ticketIds = selectSelectedTicketIds(getState())
    return dispatch(doBulkUpdateState(ticketIds, newState))
  }
}

export function doDeleteSelectedTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)

    const count = getLength(ticketIds)
    const isDelete = isDeleteHard(ticketIds, state)

    if (
      isDelete &&
      // eslint-disable-next-line no-alert
      !confirm(
        `Are you sure you want to permanently delete the selected ${app.t(
          'n_tickets',
          {
            count,
          }
        )}?`
      )
    ) {
      return null
    }

    if (!isDelete) {
      return dispatch(doBulkTrash(ticketIds))
    }
    return dispatch(doBulkDelete(ticketIds))
  }
}

export const doBulkToggleStateSelectedTickets = commonState => (
  dispatch,
  getState
) => {
  const ticketIds = selectSelectedTicketIds(getState())
  if (ticketIds.length === 0) return null

  return dispatch(doBulkToggleTicketState(ticketIds, commonState))
}

// If all the selected tickets are starred, this action wil unstar all of them.
// If some the selected tickets are starred, this action wil star the unstarred
// ones.
// If none the selected tickets are starred, this action wil star all of them
export const doBulkStarSelectedTickets = () => {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)

    if (selectAreAllSelectedStarred(state))
      return dispatch(
        doBulkUnstar(ticketIds, {
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('N_Tickets', {
                  count: ticketIds.length,
                })} unstarred`,
              },
              failed: {
                enabled: false,
              },
            },
          },
        })
      )

    return dispatch(
      doBulkStar(ticketIds, {
        moduleOptions: {
          snackbar: {
            enabled: true,
            success: {
              enabled: true,
              message: `${app.t('N_Tickets', {
                count: ticketIds.length,
              })} starred`,
            },
            failed: {
              enabled: false,
            },
          },
        },
      })
    )
  }
}

export function doBulkUpdateChannelSelectedTickets(mailboxId) {
  return (dispatch, getState) => {
    const state = getState()
    const selectedTicketIds = selectSelectedTicketIds(state)
    const isBulkSelectionMode = selectIsTicketSelectionMode(state)
    const currentTicketId = selectCurrentTicketId(state)
    const ticketIds = isBulkSelectionMode
      ? selectedTicketIds
      : [currentTicketId]

    return dispatch(doBulkUpdateChannel(ticketIds, mailboxId))
  }
}

export function doBulkAssignCurrentOrSelectedToCurrentUser() {
  return (dispatch, getState) => {
    const state = getState()
    const tickets = selectCurrentOrSelectedTickets(state)
    const currentUserId = selectCurrentUserGlobalId(state)
    const areAllAssignedToCurrentUser = all(ticket => {
      return ticket?.assigned?.agent === currentUserId
    }, tickets)
    const assignTo = areAllAssignedToCurrentUser ? null : currentUserId
    const ticketIds = tickets.map(ticket => ticket.id)
    return dispatch(doBulkAssign(ticketIds, null, assignTo))
  }
}
