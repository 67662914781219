import { SEARCH_PAGE } from 'constants/pages'
import { queryIdToQuery } from 'ducks/searches/utils/query'

export const doOpenSearchPayload = (
  ticketId,
  term,
  currentQuery,
  options = {}
) => {
  const query = { ...queryIdToQuery(term) }
  if (query && !query.orderBy && options?.defaultOrderBy) {
    query.orderBy = options.defaultOrderBy
  }
  return {
    type: SEARCH_PAGE,
    payload: {
      id: ticketId,
    },
    meta: {
      query: {
        ...currentQuery,
        ...query,
        ...(options?.query || {}),
      },
    },
  }
}
