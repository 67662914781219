import { selectAutoLoadFirstTicketInList } from 'ducks/currentUser/selectors/preferences/selectAutoLoadFirstTicketInList'
import {
  selectSearchEntityIdsByCurrentQueryId,
  selectSearchEntityIdsByQueryId,
} from 'ducks/searches/selectors'

import debug from 'util/debug'
import { doOpenTicketPage } from 'actions/pages'
import { selectIsOnSearchPage, selectIsOnTicketsPage } from 'selectors/location'
import { constructGraphQLFilterObject } from 'ducks/searches/utils/query'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectLastAutoRedirectedTicketId } from 'selectors/search'
import { isValidNumber } from './isValidNumber'

export const doOpenFirstTicketForLoad = firstTicketId => (
  dispatch,
  getState
) => {
  const state = getState()

  const autoLoadFirstTicketInList = selectAutoLoadFirstTicketInList(state)
  const isOnSearchPage = selectIsOnSearchPage(state)
  const currentTicketId = selectCurrentTicketId(state)
  const isOnTicketsPage = selectIsOnTicketsPage(state)
  const queryId = selectCurrentQueryId(state)
  const ticketIds = selectSearchEntityIdsByQueryId(state, queryId)

  const filter = constructGraphQLFilterObject(queryId, state) || {}

  const ticketId =
    firstTicketId || selectSearchEntityIdsByCurrentQueryId(state)[0]
  const lastAutoRedirectTicketId = selectLastAutoRedirectedTicketId(state)

  if (
    ticketIds.length &&
    ((isOnSearchPage &&
      ticketIds.length === 1 &&
      filter.keywords &&
      isValidNumber(filter.keywords) &&
      ticketId === filter.keywords &&
      (!lastAutoRedirectTicketId || lastAutoRedirectTicketId !== ticketId)) ||
      (((isOnSearchPage && !currentTicketId) || isOnTicketsPage) &&
        autoLoadFirstTicketInList))
  ) {
    debug('redirect to search with current first ticket loaded')
    return dispatch(
      doOpenTicketPage(ticketId, {
        preserveQuery: true,
        isAutoRedirect: true,
      })
    )
  }
  return null
}
