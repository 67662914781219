import { redirect } from 'redux-first-router'

import * as types from 'constants/action_types'

import { doUpdateAgentMention, doUpdateMentions } from 'actions/app'
import { doOpenNotePage } from 'actions/pages'

import { selectAgentsById } from 'selectors/agents/base'
import { selectAgentMention } from 'selectors/mentions'
import { selectIsAddingNote } from 'selectors/page'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import { runOnNextTick } from 'util/functions'

import editor from 'shared/editor/utils'

export * from './bulkSelection'
export * from './bulkSelectionMode'
export * from './print'
export * from './state'

export function doToggleShowCcBcc() {
  return {
    type: types.TOGGLE_SHOW_CC_BCC,
  }
}

export function doSetTicketAssignmentFilter(term) {
  return {
    type: types.SET_TICKET_ASSIGNMENT_FILTER,
    data: {
      term,
    },
  }
}

const insertAgentMention = agent => {
  if (!agent) return false

  return setTimeout(() => {
    editor.fire('mention-insert', {
      mention: `@${agent.username}`,
    })

    // eslint-disable-next-line no-undef
    tinymce.activeEditor.fire('change')
  }, 50)
}

export function doMentionAgent(agentId, _editorInstance) {
  return (dispatch, getState) => {
    const state = getState()
    const agent = selectAgentsById(state)[agentId]
    if (!agent) return Promise.reject('cannot find agent')

    const agentMention = selectAgentMention(state)
    // NOTE (jscheel): If we are mentioning an agent from a partial match that
    // is typed in the editor, we use that partial match. Otherwise, we try to
    // insert the mention normally.
    if (agentMention) {
      const editorInstance = _editorInstance || editor.getEditor()

      if (editorInstance && editorInstance.fire) {
        editorInstance.fire('mention-select', {
          agent,
          mention: `@${agent.username}`,
        })

        // eslint-disable-next-line no-undef
        editorInstance.fire('change')
      }
      dispatch(doUpdateMentions(agent.id))
      return dispatch(doUpdateAgentMention(null))
    }

    const ticketId = selectCurrentTicketId(state)
    const isAddingNote = selectIsAddingNote(state)

    if (!isAddingNote) dispatch(redirect(doOpenNotePage(ticketId)))

    runOnNextTick(() => {
      insertAgentMention(agent)
    })

    return Promise.resolve(agent)
  }
}
