import { selectTicketEventGroupsByConversationId } from 'ducks/tickets/selectors/selectTicketEventGroupsByConversationId'
import { doFetchTicketEventsByGroupId } from 'ducks/tickets/actions/doFetchTicketEventsByGroupId'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'

export function doFetchTicketEventsGroups(ticketId) {
  return (dispatch, getState) => {
    const state = getState()
    const eventGroups = selectTicketEventGroupsByConversationId(state, ticketId)

    return Promise.all(
      eventGroups
        .map(group => {
          if (!group.collapsed) return null

          return dispatch(
            doFetchTicketEventsByGroupId({
              conversationId: ticketId,
              eventGroupId: group.id,
              channelType: MAILBOX_CHANNEL_TYPE,
            })
          )
        })
        .filter(r => !!r)
    )
  }
}
