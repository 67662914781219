import config from 'config'
import { redirect, push, NOT_FOUND } from 'redux-first-router'
import { v4 as uuidV4 } from 'uuid'

import grooveAPI from 'api/groove'
import OAuthWindow, { ERR_USER_CLOSED_POPUP } from 'util/oauth_window'
import * as pages from 'constants/pages'
import * as types from 'constants/action_types'

import { doClearKnowledgeBaseAuthCookie } from 'subapps/kb/actions/knowledge_bases'
import {
  getMarketingAttribution,
  getSignupAccountAttributes,
} from 'subapps/onboarding/utils'

import { doReloadTokenInfo } from 'ducks/tokens/actions'

// Public duck pages
import {
  SIGNUP_PAGE,
  ACCEPT_INVITE_PAGE,
  V2_SIGNUP_PAGE,
  V3_SIGNUP_PAGE,
  V3_EMAIL_PAGE,
  V4_EMAIL_PAGE,
  V5_SIGNUP_PAGE,
  V5_EMAIL_PAGE,
  V6_SIGNUP_PAGE,
  V6_EMAIL_PAGE,
  SHOPIFY_EMAIL_PAGE,
} from 'subapps/onboarding/pages'

import {
  selectPrevious,
  selectQueryParams,
  selectAccountSubdomainFromLocation,
} from 'selectors/location'
import { selectOtpIdentifier, selectReturnTo } from 'selectors/app'

import storage from 'util/storage'
import sharedStorage from 'util/sharedStorage'
import debug from 'util/debug'
import { toQueryString } from 'util/params'
import { reset as resetTracking } from 'ducks/tracking/actions'
import { disableInstall } from 'util/windowUnload'
import { RECENT_TICKETS_SEARCH_QUERIES_STORAGE_KEY } from 'util/search/constants'
import { MAILBOX_PERSIST_KEY } from 'ducks/searches/constants'

const PRESERVE_SESSION_ITEMS = [
  'enableBusinessHours',
  'reportsTimeframe',
  'showBusinessHoursNotice',
  'env',
  'lastAccessedKnowledgeBaseId',
  '3-columns-widths-v3',
  '2-columns-widths-v2',
  'editorPinned',
  RECENT_TICKETS_SEARCH_QUERIES_STORAGE_KEY,
  MAILBOX_PERSIST_KEY,
]

export const PUBLIC_PAGES = [
  NOT_FOUND,
  pages.LOGIN_PAGE,
  pages.LOGIN_MULTIPLE_ACCOUNTS_PAGE,
  pages.LOGIN_SELECTOR,
  pages.LOGIN_AGENT_MISSING_PAGE,
  pages.LOGOUT_PAGE,
  pages.AUTH_CALLBACK_PAGE,
  SIGNUP_PAGE,
  ACCEPT_INVITE_PAGE,
  pages.FORGOT_SUBDOMAIN_PAGE,
  pages.FORGOT_PASSWORD_PAGE,
  pages.CHANGE_PASSWORD_PAGE,
  pages.OTP_CHALLENGE_PAGE,
  pages.OAUTH_ERROR_PAGE,
  // shopify onboarding,
  SHOPIFY_EMAIL_PAGE,
  // v2 onboarding
  V2_SIGNUP_PAGE,
  // v3 onboarding
  V3_SIGNUP_PAGE,
  V3_EMAIL_PAGE,
  // v4 onboarding
  V4_EMAIL_PAGE,
  // v5 onboarding
  V5_SIGNUP_PAGE,
  V5_EMAIL_PAGE,
  // v6 onboarding
  V6_SIGNUP_PAGE,
  V6_EMAIL_PAGE,
]

// NOTE (jscheel): Legacy API does not wrap `user`. To account for this, we version
// the API so that it knows when to nest the  user fields to under the `user` object.
// Added on 2019-05-19
const PAYLOAD_VERSION = 2

export function doLoginError(error) {
  return {
    type: types.LOGIN_ERROR,
    data: {
      error,
    },
  }
}

export function doLoginMultipleAccounts(data) {
  return (dispatch, getState) => {
    const query = selectQueryParams(getState())
    dispatch({
      type: pages.LOGIN_MULTIPLE_ACCOUNTS_PAGE,
      payload: data,
      meta: {
        query,
      },
    })
  }
}

const getAuthState = ({ success, json }) => {
  if (success) return json.otp_challenge ? 'CHALLENGE' : 'SUCCESS'

  return 'FAILURE'
}

export const doAuthenticateOnServer = (email, password, subdomain) => {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOGIN_REQUEST, data: { email, subdomain } })

    if (subdomain?.startsWith('fullstacktest')) {
      disableInstall()
    }

    let csid = sessionStorage.getItem('csid')
    if (!csid) {
      csid = uuidV4()
      sessionStorage.setItem('csid', csid)
    }

    const variables = {
      user: {
        email,
        password,
        subdomain,
        mobile: 'false',
      },
      csid,
      version: PAYLOAD_VERSION,
    }
    return grooveAPI
      .post(null, 'v1/authorize.json', {}, JSON.stringify(variables))
      .then(res => {
        const { json = {} } = res || {}
        const { user = {} } = json || {}

        const authState = getAuthState(res || {})
        let data = {
          otpIdentifier: json.otp_identifier,
          otpChallenge: json.otp_challenge,
          errors: json.errors,
          authState,
        }
        switch (data.authState) {
          case 'SUCCESS': {
            data = {
              ...data,
              id: user.id,
              email: user.email,
              firstName: user.first_name,
              lastName: user.last_name,
              token: user.token,
              expiresAt: user.token_expires_at,
            }
            handleAuthenticationSuccessState(dispatch, data)
            break
          }
          case 'CHALLENGE': {
            handleAuthenticationChallengeState(dispatch, data, getState)
            break
          }
          case 'FAILURE': {
            handleAuthenticationFailureState(dispatch, data)
            break
          }
          default: {
            handleAuthenticationUnknownState(dispatch, data)
            break
          }
        }
      })
      .catch(e => {
        handleAuthenticationError(dispatch, e)
      })
  }
}

export function doVerifyOtpOnServer(code) {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOGIN_REQUEST, data: { code } })

    const state = getState()

    const payload = {
      code,
      otp_identifier: selectOtpIdentifier(state),
      csid: sessionStorage.getItem('csid'),
      version: PAYLOAD_VERSION,
    }

    return grooveAPI
      .post(null, 'v1/verify_otp.json', {}, JSON.stringify(payload))
      .then(res => {
        const authState = getAuthState(res || {})
        const { json = {} } = res || {}
        const { user = {} } = json || {}
        let data = {
          otpIdentifier: json.otp_identifier,
          otpChallenge: json.otp_challenge,
          errors: json.errors,
          authState,
        }
        switch (data.authState) {
          case 'SUCCESS': {
            data = {
              ...data,
              id: user.id,
              email: user.email,
              firstName: user.first_name,
              lastName: user.last_name,
              token: user.token,
              expiresAt: user.token_expires_at,
            }
            handleAuthenticationSuccessState(dispatch, data)
            sessionStorage.removeItem('csid')
            break
          }
          case 'CHALLENGE': {
            handleAuthenticationChallengeState(dispatch, data, getState)
            break
          }
          case 'FAILURE': {
            handleAuthenticationFailureState(dispatch, data)
            dispatch(redirect({ type: pages.LOGIN_PAGE }))
            break
          }
          default: {
            handleAuthenticationUnknownState(dispatch, data)
            break
          }
        }
      })
      .catch(e => {
        handleAuthenticationError(dispatch, e)
      })
  }
}

function handleAuthenticationSuccessState(dispatch, data) {
  const { token, expiresAt } = data || {}
  dispatch({
    type: types.LOGIN_SUCCESS,
    data: { token, user: data },
  })
  saveToken(token, null, expiresAt)
  dispatch(redirectBackOrGoHome())
}

function handleAuthenticationChallengeState(dispatch, data, getState) {
  dispatch({
    type: types.LOGIN_CHALLENGE,
    data: { otpIdentifier: data.otpIdentifier },
  })
  const previousPage = selectPrevious(getState())
  const payload = {
    returnTo: previousPage,
  }
  dispatch(redirect({ type: pages.OTP_CHALLENGE_PAGE, payload }))
}

function handleAuthenticationFailureState(dispatch, data) {
  let errorMessage = 'Failed to login, please try again'
  if (data.errors && data.errors.length > 0) {
    errorMessage = data.errors.join(' ')
  }
  dispatch(doLoginError(errorMessage))
}

function handleAuthenticationUnknownState(dispatch, data) {
  const { errors } = data || {}
  const message =
    errors && errors.length > 0
      ? errors[0]
      : 'Failed to login, please try again'
  dispatch(doLoginError(message))
}

function handleAuthenticationError(dispatch, e) {
  debug(e)
  dispatch(doLoginError('Login error, please try again in a moment'))
}

export function doAuthenticateFromToken(token, expiresAt = null) {
  return dispatch => {
    dispatch({
      type: types.LOGIN_SUCCESS,
      data: { token, expiresAt },
    })
    saveToken(token, null, expiresAt)
    dispatch(doReloadTokenInfo())
  }
}

function saveToken(token, previous = null, expiresAt = null) {
  const expiresTimestamp = expiresAt
    ? new Date(expiresAt).getTime() / 1000
    : null
  storage.set('auth', {
    authenticated: true,
    token,
    expiresAt: expiresTimestamp,
    previousToken: previous,
  })
  sharedStorage.setAPIAuthToken(token)
}

export async function doSetupAuthCallbackPage(dispatch, getState) {
  const state = getState()
  const { access_token: token, destination, impersonate } = selectQueryParams(
    state
  )
  let previousToken = null
  if (impersonate) previousToken = (storage.get('auth') || {}).token

  // make sure we clear storage before proceeding
  storage.clear({ except: PRESERVE_SESSION_ITEMS })
  sharedStorage.clear()
  // ensure we clear redux store

  saveToken(token, previousToken)
  if (impersonate) {
    localStorage.setItem('groove-impersonating', true)
  }

  await dispatch(doReloadTokenInfo())

  if (previousToken) {
    return (window.location.href = '/')
  }

  if (destination) {
    // RFR workaround - we dip into the RFR client API to ensure our history is
    // tracked correctly.
    return push(destination) // this will trigger a route action automagically...
  }

  return dispatch(redirect({ type: pages.MAIN_PAGE }))
}

function redirectBackOrGoHome() {
  return (dispatch, getState) => {
    const returnTo = selectReturnTo(getState())
    if (returnTo && returnTo.type && !PUBLIC_PAGES.includes(returnTo.type)) {
      const { type, payload } = returnTo
      dispatch(redirect({ type, payload }))
      dispatch({ type: types.CLEAR_RETURN_TO })
      return true
    }

    return dispatch({ type: pages.MAIN_PAGE })
  }
}

// Do not call this directly. It will be called when you dispatch LOGOUT_PAGE
export function doLogout() {
  return async dispatch => {
    const { token, previousToken } = storage.get('auth') || {}

    // Revoke the credentials on the server
    await Promise.all([
      dispatch(doClearKnowledgeBaseAuthCookie()),
      grooveAPI.post(token, 'oauth/revoke', { token }),
    ])
    // clear store
    storage.clear({ except: PRESERVE_SESSION_ITEMS })
    sharedStorage.clear()

    // We intentionally require the module here to ensure
    // that the chat dependancies are not included in the
    // main inbox bundle
    const {
      default: matrixEnvironment,
    } = await import('ducks/chat/MatrixEnvironment').load()
    await matrixEnvironment.logout()

    // Clear posthog tracking when logging out
    resetTracking()

    if (previousToken) {
      saveToken(previousToken)
      window.location.href = '/'
    } else {
      window.location.href = '/login'
    }
  }
}

function doOauth(oAuthWindowOptions) {
  return dispatch => {
    const oAuthFlow = new OAuthWindow(oAuthWindowOptions)
    const p = new Promise((resolve, reject) => {
      oAuthFlow
        .start()
        .then(data => {
          // Support responses from both the login_success and registration_success
          const accessToken = data.mobile_token || data.access_token
          if (accessToken) {
            dispatch(doAuthenticateFromToken(accessToken))
            if (data.login) {
              // NOTE (jscheel): Edge case where the user already has account
              dispatch({ type: pages.MAIN_PAGE })
              return resolve(true)
            }
            return resolve()
          }
          if (data.accounts) {
            // NOTE (jscheel): Edge case where the user is associated with multiple accounts
            dispatch(doLoginMultipleAccounts(data))
            return resolve(true)
          }
          if (data.agentMissing) {
            // NOTE (jscheel): Edge case where the user tries to sign in with an
            // email address that is no associated with any agents.
            dispatch({ type: pages.LOGIN_AGENT_MISSING_PAGE })
            return resolve(true)
          }
          throw new Error('Unhandled SSO response', data)
        })
        .catch(err => {
          oAuthFlow.cancel()
          reject(err)
        })
    })
    return p
  }
}

const oauthParams = (extra, query) => {
  const params = {
    onboarding_version: 2,
    ...getSignupAccountAttributes({ query }),
    ...extra,
  }

  if (extra?.signup) {
    const marketing = getMarketingAttribution()
    Object.keys(marketing).forEach(k => {
      params[`ma_${k}`] = marketing[k]
    })
  }

  return toQueryString(params)
}
const urlPrefix = `${config.api_url}/auth`

export function doGoogleOauth(
  destinationPage,
  extraOauthParams = {},
  options = {}
) {
  return (dispatch, getState) => {
    const state = getState()
    const query = selectQueryParams(state)
    const subdomain = selectAccountSubdomainFromLocation(state)
    const params = oauthParams(
      {
        scope:
          'https://www.googleapis.com/auth/userinfo.email,https://www.googleapis.com/auth/userinfo.profile',
        ...extraOauthParams,
        ...(subdomain && { subdomain }),
      },
      query
    )
    return dispatch(
      doOauth({
        url: `${urlPrefix}/google_oauth2?${params}`,
        width: 600,
        height: 600,
      })
    )
      .then(redirected => {
        if (!redirected && !options.disableRedirect) {
          dispatch({ type: destinationPage })
        }
        return !redirected
      })
      .catch(err => {
        if (err !== ERR_USER_CLOSED_POPUP) {
          dispatch({
            type: pages.OAUTH_ERROR_PAGE,
            payload: { service: 'Google', error: err },
          })
        } else if (
          err === ERR_USER_CLOSED_POPUP &&
          options.rethrowErrorOnClosedPopup
        ) {
          throw err
        }
        return false
      })
  }
}

export function doOfficeOauth(
  destinationPage,
  extraOauthParams = {},
  options = {}
) {
  return (dispatch, getState) => {
    const state = getState()
    const query = selectQueryParams(state)
    const subdomain = selectAccountSubdomainFromLocation(state)
    const params = oauthParams(
      {
        ...extraOauthParams,
        ...(subdomain && { subdomain }),
        redirect_uri: `${urlPrefix}/microsoft_office365/callback`,
      },
      query
    )
    return dispatch(
      doOauth(
        {
          url: `${urlPrefix}/microsoft_office365?${params}`,
          width: 600,
          height: 600,
        },
        destinationPage
      )
    )
      .then(redirected => {
        if (!redirected && !options.disableRedirect) {
          dispatch({ type: destinationPage })
        }
        return !redirected
      })
      .catch(err => {
        if (err !== ERR_USER_CLOSED_POPUP) {
          dispatch({
            type: pages.OAUTH_ERROR_PAGE,
            payload: { service: options.service || 'Office 365', error: err },
          })
        } else if (
          err === ERR_USER_CLOSED_POPUP &&
          options.rethrowErrorOnClosedPopup
        ) {
          throw err
        }
        return false
      })
  }
}
